import React, { FC, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import styles from './styles.module.scss';
import { t } from '../../i18n';
import { YellowHeader } from '../../components/main/YellowHeader';
import { Card } from '../../components/common/Card';
import { IHeaderQuery } from '../../queries/headers/types';
import { IPortfolioTagsQuery } from '../../queries/portfolioTags/types';
import {
  IDirectionTagsQuery,
  IDirectionTagsQueryNode,
} from '../../queries/directionTags/types';
import useScreen from '../../utils/hooks/useScreen';

type IProps = PageProps<
  IDirectionTagsQuery & IHeaderQuery & IPortfolioTagsQuery,
  { locale: string; tag: string; page: number }
>;

const DirectionsPage: FC<IProps> = ({
  location,
  data,
  pageContext: { locale = 'ru' },
}) => {
  const directions: IDirectionTagsQueryNode[] = data.directionTags.edges;

  const header = useMemo(
    () => data.headers.edges.find((edge) => edge.node),
    [data.headers]
  );
  const { isMobile } = useScreen();
  return (
    <MainLayout locale={locale} title="menu.directions">
      <YellowHeader header={t('directions.title')}>
        {header?.node?.html || ''}
      </YellowHeader>

      <div className={classnames('content', styles.wrap)}>
        <div className={styles.grid}>
          {directions.map(({ node }) => (
            <Card
              node={node}
              key={node.fileAbsolutePath}
              from="/directions/"
              headDirection={isMobile ? 'row-reverse' : 'row'}
            />
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/directions/tags/" }
        frontmatter: { isMain: { eq: true } }
      }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      ...DirectionTagsFields
    }

    portfolioTags: allMarkdownRemark(
      limit: 2000
      filter: { fileAbsolutePath: { regex: "/cases/projects/" } }
    ) {
      group(field: frontmatter___type) {
        ...PortfolioTagsFields
      }
    }

    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/header/header/" } }
    ) {
      ...HeaderFields
    }
  }
`;
export default DirectionsPage;
